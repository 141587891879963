import React, { useState, useEffect } from 'react'
import Router from 'next/router'
import { useMutation } from '@apollo/react-hooks'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import { logOutMutation } from '../../graphql/account'
import { publicLinks, privateLinks } from '../../lib/constants/links'
import NavDropDown from '../NavDropDown'
import CreateDesign from '../CreateDesign'

const AccountMenu = ({ user }) => {
  const [runlogout] = useMutation(logOutMutation)
  const [anchorEl, setAnchorEl] = useState(null)
  const [listItems, setListItems] = useState(publicLinks)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'primary-search-account-menu'

  const toggleProfileMenu = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const performAction = async (e, action) => {
    if (!action) return

    e.preventDefault()

    if (action === 'logout') {
      await runlogout()
      Router.push('/login')
    }
  }

  useEffect(() => {
    setListItems(
      user?.agency && user?.agency?.active
        ? privateLinks
        : privateLinks.filter((link) => !link.partnerOnly)
    )
  }, [user])

  const renderAccountLinks = listItems.map((item) => {
    const { text, to, dropdown, action, variant } = item

    if (dropdown) return <NavDropDown {...item} key={text} />

    const onClickHandler = action
      ? (e) => {
          toggleProfileMenu(e)
          performAction(e, action)
        }
      : (e) => {
          toggleProfileMenu(e)
        }

    return (
      <div key={text}>
        {item.divider && <Divider />}
        <MenuItem
          href={to}
          onClick={onClickHandler}
          component={Link}
          color='inherit'
          variant={variant}
        >
          {text}
        </MenuItem>
      </div>
    )
  })

  const renderProfileMenu = (
    <Popper
      anchorEl={anchorEl}
      placement='bottom'
      style={{ zIndex: 1300 }}
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent'
        },
        arrow: {
          enabled: true
        }
      }}
      id={menuId}
      open={isMenuOpen}
      transition
    >
      <Paper>{renderAccountLinks}</Paper>
    </Popper>
  )

  return (
    <>
      {user ? (
        <Box display='flex'>
          <CreateDesign />
          <MenuItem
            component={Button}
            edge='end'
            aria-label='account of current user'
            aria-controls={menuId}
            aria-haspopup='true'
            onClick={toggleProfileMenu}
            color='inherit'
          >
            {user.avatar ? (
              <Avatar src={user.avatar} />
            ) : (
              <AccountCircleOutlined />
            )}
            <Box ml={0.5} display='flex' alignItems='center'>
              <Typography variant='overline'>{user.username}</Typography>
              <ArrowDropDown />
            </Box>
          </MenuItem>
          {renderProfileMenu}
        </Box>
      ) : (
        <>{renderAccountLinks}</>
      )}
    </>
  )
}
export default AccountMenu
