import { styled } from '@material-ui/core/styles'

export const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  color: theme.palette.text.primary
}))
export const MainContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  color: theme.palette.text.primary,
  position: 'relative'
}))
