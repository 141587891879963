import { styled } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'

export const TransitionAppBar = styled(AppBar)(({ color }) => ({
  transitionProperty: 'background-color',
  transitionDuration: 500,
  transitionTimingFunction: 'ease',
  backgroundColor:
    color === 'transparent'
      ? color
      : color === 'default'
      ? 'rgba(255,255,255)'
      : 'rgba(42, 118, 226)'
}))

export const DesktopMainMenuContainer = styled('div')(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

export const DesktopAccountMenuContainer = styled('div')(({ theme }) => ({
  display: 'none',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

export const MobileMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

export const FlexToolbar = styled('div')(({ theme, mode }) => ({
  color: mode === 'light' ? theme.palette.text.primary : 'white'
}))
