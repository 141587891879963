import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link as NextLink } from 'next/link'

import ExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import {
  clearSelectedFiltersMutation,
  getTemplateCategoriesList
} from '../../graphql/templates'
import LinkNoUnderline from '../../lib/styles/components/LinkNoUnderline'

const NavDropDown = ({ to, text }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const {
    data: { categories }
  } = useQuery(getTemplateCategoriesList, {
    ssr: false
  })

  const [clearSelectedFilters] = useMutation(clearSelectedFiltersMutation)

  return (
    <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <LinkNoUnderline
        color='inherit'
        component={NextLink}
        href={`${to}/recommended`}
      >
        <MenuItem
          color='inherit'
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
        >
          {text}
          <ExpandMore />
        </MenuItem>
      </LinkNoUnderline>
      <Popper
        id='mouse-over-popover'
        style={{ zIndex: 1300 }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
        onClose={handlePopoverClose}
        PaperProps={{
          onMouseLeave: handlePopoverClose
        }}
      >
        <Paper elevation={3}>
          <LinkNoUnderline
            color='inherit'
            component={NextLink}
            href={`${to}/recommended`}
          >
            <MenuItem onClick={clearSelectedFilters} color='inherit'>
              Recommended
            </MenuItem>
          </LinkNoUnderline>
          <Divider />
          {categories &&
            categories.edges.map(({ node: { id, name, slug } }) => (
              <LinkNoUnderline
                color='inherit'
                component={NextLink}
                key={id}
                href={`${to}/explore?categories=${slug}`}
              >
                <MenuItem onClick={clearSelectedFilters} color='inherit'>
                  {name}
                </MenuItem>
              </LinkNoUnderline>
            ))}
          <Divider />
          <LinkNoUnderline
            color='inherit'
            component={NextLink}
            href={`${to}/explore`}
          >
            <MenuItem
              onClick={clearSelectedFilters}
              className='text-secondary'
              color='inherit'
            >
              Explore All
            </MenuItem>
          </LinkNoUnderline>
        </Paper>
      </Popper>
    </div>
  )
}
export default NavDropDown
