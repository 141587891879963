import { styled } from '@material-ui/core/styles'

export const HeaderToolbar = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const HeaderForm = styled('form')(() => ({
  display: 'flex',
  margin: 0,
  flex: 1
}))
