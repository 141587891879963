import React, { useState, useRef } from 'react'
// import { Link as NextLink } from 'next/link'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import LabelImportant from '@material-ui/icons/LabelImportant'
import Router from 'next/router'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import AccountMenu from '../AccountMenu'
import { HeaderForm, HeaderToolbar } from './editorHeader.styles'
import { commonLinks } from '../../lib/constants/links'
import NavDropDown from '../NavDropDown'
import {
  UPDATE_USER_IMAGE_TEMPLATE,
  UPDATE_USER_VIDEO_TEMPLATE
} from '../../graphql/userTemplates'

const EditorHeader = ({ user, template }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    id,
    name: originalName,
    template: { type }
  } = template
  const [name, setName] = useState(originalName)
  const currentName = useRef(name)

  const [updateImageTemplate] = useMutation(UPDATE_USER_IMAGE_TEMPLATE, {
    onError: () => enqueueSnackbar('Update Failed', { variant: 'error' })
  })

  const [updateVideoTemplate] = useMutation(UPDATE_USER_VIDEO_TEMPLATE, {
    onError: () => enqueueSnackbar('Update Failed', { variant: 'error' })
  })

  const performAction = async (e, action) => {
    if (!action) return

    e.preventDefault()
  }

  const handleNameChange = (newName) => {
    setName(newName)
  }

  const handleNameSubmit = async () => {
    currentName.current = name
    const variables = {
      id,
      name
    }
    if (type.displayName === 'Image') await updateImageTemplate({ variables })
    else await updateVideoTemplate({ variables })
  }

  const handleBlur = () => {
    if (name !== currentName.current) {
      handleNameSubmit()
    }
  }

  const mainMenu = commonLinks.map((item) => {
    const { text, to, dropdown, action, external } = item

    if (dropdown) {
      return <NavDropDown {...item} key={text} />
    }

    const onClickHandler = action
      ? (e) => performAction(e, action)
      : () => Router.push(to)

    const props = external ? { href: to } : { onClick: onClickHandler }

    return (
      <MenuItem key={text} {...props} component={Link} color='inherit'>
        {text}
      </MenuItem>
    )
  })

  return (
    <AppBar color='default' position='static' elevation={0}>
      <Toolbar component={HeaderToolbar}>
        <Box display='flex' alignItems='center' flexGrow={1}>
          {/* <Link href='/home' component={NextLink}> */}
          <img
            src='/static/images/Dropmock-AI-logo.svg'
            width='140'
            alt='DropMock'
          />
          {/* </Link> */}
          <Box px={2} height='64px'>
            <Divider orientation='vertical' />
          </Box>
          <HeaderForm>
            <InputBase
              value={name}
              fullWidth
              onChange={(e) => handleNameChange(e.target.value)}
              onBlur={handleBlur}
              inputProps={{ 'aria-label': 'naked' }}
              startAdornment={
                <InputAdornment position='start'>
                  <LabelImportant fontSize='small' />
                </InputAdornment>
              }
            />
          </HeaderForm>
        </Box>
        <Box display='flex' alignItems='center'>
          {mainMenu}
          <Box height='64px' mx={2}>
            <Divider orientation='vertical' />
          </Box>
          <Box>
            <AccountMenu user={user} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default EditorHeader
