import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React, { useState, useEffect } from 'react'
import Popover from '@material-ui/core/Popover'
import { useMutation } from '@apollo/react-hooks'
import Router from 'next/router'
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import { createBlankUserImageTemplateMutation } from '../../graphql/templates'
import { PopoverTypography } from './CreateDesign.styles'
import { calculateRatio } from '../../utils/helpers'

const CreateDesign = () => {
  /**
   * state variables
   */
  const [anchorEl, setAnchorEl] = useState(null)
  const [error, setError] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [lockOn, setLockOn] = useState(false)
  const [lockRatio, setLockRatio] = useState(null)
  const [mutationInput, setMutationInput] = useState({
    width: 1920,
    height: 1080
  })

  /**
   *
   * @param {name of attribute} attr
   * @param {value of attribute} val
   */
  const handleInputChange = (attr, val) => {
    if (lockRatio) {
      const ratioFactor = lockRatio.height / lockRatio.width
      if (attr === 'height') {
        setMutationInput({
          height: parseFloat(val),
          width: parseFloat(val / ratioFactor)
        })
      } else {
        setMutationInput({
          height: parseFloat(val * ratioFactor),
          width: parseFloat(val)
        })
      }
    } else {
      setMutationInput({
        ...mutationInput,
        [attr]: parseFloat(val)
      })
    }
  }

  /**
   * mutation for creating new blank image template
   */
  const [createBlankTemplate, { loading }] = useMutation(
    createBlankUserImageTemplateMutation,
    {
      variables: {
        size: mutationInput
      },
      onError: (err) => {
        setError(err)
      },
      onCompleted: (data) => {
        setCompleted(true)
        const templateId = data.createBlankUserImageTemplate.id
        Router.push('/editor', `/editor/user/image/${templateId}`, {
          query: { id: templateId, type: 'image' }
        })
      }
    }
  )

  /*
    handle lock change and calculate aspect ratio
    */
  useEffect(() => {
    if (!lockOn) {
      setLockRatio(null)
      return
    }
    const aspectRatio = calculateRatio(
      mutationInput.width,
      mutationInput.height
    )
    setLockRatio({
      width: aspectRatio[0],
      height: aspectRatio[1]
    })
  }, [lockOn])

  // handle popover click event
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // handle popover close event
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box display='flex' alignItems='center'>
      <Button
        aria-describedby={id}
        variant='contained'
        color='secondary'
        onClick={handleClick}
      >
        Create Design
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {completed ? (
          <>
            <PopoverTypography align='center' variant='h6'>
              Redirecting to Editor
            </PopoverTypography>
            <LinearProgress color='primary' />
          </>
        ) : (
          <>
            <PopoverTypography align='center' variant='h6'>
              Create a Custom Design
            </PopoverTypography>
            <Divider />
            <Box display='flex' padding={2}>
              <TextField
                size='small'
                onChange={(e) => handleInputChange('width', e.target.value)}
                placeholder='1920'
                id='outlined-basic'
                label='width'
                variant='outlined'
                type='number'
                value={mutationInput.width}
              />
              <PopoverTypography align='center'>
                {lockOn ? (
                  <LockOutlined
                    color='primary'
                    onClick={() => setLockOn(false)}
                  />
                ) : (
                  <LockOpenOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => setLockOn(true)}
                    color='disabled'
                  />
                )}
              </PopoverTypography>
              <TextField
                size='small'
                onChange={(e) => handleInputChange('height', e.target.value)}
                placeholder='1080'
                id='outlined-basic'
                label='height'
                variant='outlined'
                value={mutationInput.height}
                type='number'
              />
            </Box>
            {lockRatio && (
              <Box display='flex' paddingX={2} paddingBottom={2}>
                <Typography align='center' size='small'>
                  {`You have locked Aspect Ratio at ${lockRatio.width} : ${lockRatio.height}`}
                </Typography>
              </Box>
            )}
            <Box display='flex' paddingX={2} paddingBottom={2}>
              <Button
                disabled={loading}
                onClick={createBlankTemplate}
                fullWidth
                color='primary'
                variant='contained'
              >
                {loading ? (
                  <CircularProgress color='primary' />
                ) : (
                  'Create Custom Design'
                )}
              </Button>
            </Box>
            {error &&
              error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
          </>
        )}
      </Popover>
    </Box>
  )
}

export default CreateDesign
