import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'

import MoreVert from '@material-ui/icons/MoreVert'

const MobileMainMenu = ({ children }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {children}
    </Menu>
  )

  return (
    <>
      <IconButton
        aria-label='show more'
        aria-controls={mobileMenuId}
        aria-haspopup='true'
        onClick={handleMobileMenuOpen}
        color='inherit'
      >
        <MoreVert />
      </IconButton>
      {renderMenu}
    </>
  )
}

export default MobileMainMenu
