import React from 'react'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const FooterSection = ({ title, links }) => {
  return (
    <Box p={2}>
      <Typography variant='h5'>{title}</Typography>
      <Box my={2} display='flex' flexDirection='column'>
        {links.map((link, i) => (
          <Link href={link.target} key={i} target='_blank' variant='body2'>
            {link.name}
          </Link>
        ))}
      </Box>
    </Box>
  )
}

export default FooterSection
