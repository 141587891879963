import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import Instagram from '@material-ui/icons/Instagram'
import Link from '@material-ui/core/Link'

import {
  resources,
  socials,
  mostPopular
} from '../../lib/constants/footerLinks'

import FooterSection from '../FooterSection'

const Footer = () => {
  return (
    <>
      <Divider />
      <Box py={3}>
        <Container maxWidth='lg'>
          <Grid container>
            <Grid item xs>
              <Box p={2}>
                <Typography variant='h5'>Stay Updated</Typography>
                <Box mb={2} display='flex' flexDirection='column'>
                  <TextField label='Your Name' />
                  <TextField label='Your Email' />
                </Box>
                <Button
                  color='primary'
                  variant='contained'
                  disableElevation
                  fullWidth
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
            <Grid item xs>
              <FooterSection title='Resources' links={resources} />
            </Grid>
            <Grid item xs>
              <FooterSection title='Socials' links={socials} />
            </Grid>
            <Grid item xs={4}>
              <FooterSection
                title='Most Popular Templates'
                links={mostPopular}
              />
            </Grid>
          </Grid>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            mb={3}
          >
            <img
              src='/static/images/Dropmock-AI-logo.svg'
              height='70px'
              alt='DropMock'
            />
            <div>
              <Link href='https://www.facebook.com/dropmock/' target='_blank'>
                <IconButton>
                  <Facebook />
                </IconButton>
              </Link>
              <Link href=' https://twitter.com/dropmock' target='_blank'>
                <IconButton>
                  <Twitter />
                </IconButton>
              </Link>
              <Link href='https://www.instagram.com/dropmock/' target='_blank'>
                <IconButton>
                  <Instagram />
                </IconButton>
              </Link>
            </div>
            <Typography variant='caption'>
              © DropMock. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Footer
