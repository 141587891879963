import React from 'react'
import App from 'next/app'
import { ApolloProvider } from '@apollo/react-hooks'
import { SnackbarProvider } from 'notistack'
import { IntercomProvider } from 'react-use-intercom'
import Close from '@material-ui/icons/Close'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import withNextApollo from '../apollo'
import { getTemplateCategoriesList } from '../graphql/templates'
import checkLoggedIn from '../lib/checkLoggedIn'

import getQuery from '../lib/getQuery'
import Layout from '../layouts/Default'
import theme, { generateClassName } from '../lib/styles/theme'

import '../lib/styles/global.scss'

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    const { apolloClient } = ctx
    const { loggedInUser } = await checkLoggedIn(apolloClient)

    await getQuery(apolloClient, {
      query: getTemplateCategoriesList
    })

    return { pageProps, loggedInUser }
  }

  render() {
    const { Component, pageProps, apollo, loggedInUser } = this.props
    // add action to all snackbars
    const notistackRef = React.createRef()
    const onClickDismiss = (key) => () => {
      notistackRef.current.closeSnackbar(key)
    }

    if (process.browser) {
      return (
        <ApolloProvider client={apollo}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                ref={notistackRef}
                maxSnack={5}
                action={(key) => (
                  <IconButton color='inherit' onClick={onClickDismiss(key)}>
                    <Close />
                  </IconButton>
                )}
              >
                <IntercomProvider appId='kigbnimk' autoBoot={false}>
                  <Layout {...pageProps} user={loggedInUser}>
                    <Component {...pageProps} user={loggedInUser} />
                  </Layout>
                </IntercomProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StylesProvider>
        </ApolloProvider>
      )
    }
    return <div />
  }
}

export default withNextApollo(MyApp)
