import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import routes from '../../utils/routes'

const RouteGuard = ({ children, user }) => {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)

  const authCheck = (url) => {
    // redirect to login page if accessing a private page and not logged in
    const authRoutes = routes.reduce(
      (acc, route) => (route.settings.auth ? [...acc, route.url] : acc),
      []
    )
    // TODO: Handle redirection to dashboard in case if public routes
    // AUTHORIZATION CHECK
    if (authRoutes.includes(url) && !user) {
      setAuthorized(false)
      router.push('/login')
    } else {
      setAuthorized(true)
    }
  }

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.pathname)
  }, [router.asPath])

  return authorized && children
}

export default RouteGuard
