import React, { forwardRef } from 'react'
import Close from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'

import TemplatePreview from '../TemplatePreview'
import { PreviewDialogContainer } from './TemplatePreviewDialog.styles'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const TemplatePreviewDialog = ({ open, onClose }) => {
  return (
    <PreviewDialogContainer
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position='static' color='transparent' elevation={0}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={onClose}
            aria-label='close'
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Divider />
      <Box position='static' overflow='auto' height='100%'>
        <TemplatePreview />
      </Box>
    </PreviewDialogContainer>
  )
}

export default TemplatePreviewDialog
