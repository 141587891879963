export const resources = [
  { name: 'Help Center', target: 'https://bonus.dropmock.com/tutorials' },

  { name: 'Pricing', target: 'https://bonus.dropmock.com/aireveal' },

  { name: 'Contact us', target: 'mailto:support@dropmock.com' },

  { name: 'Be a contributor', target: 'mailto:support@dropmock.com' },

  { name: 'Terms of Use', target: 'https://all-in-one.dropmock.com/terms' },

  { name: 'Privacy Policy', target: 'https://dropmock.com/privacy/' },

  {
    name: 'Earnings Disclaimer',
    target: 'https://all-in-one.dropmock.com/earnings-disclaimer'
  },

  { name: 'Careers', target: 'mailto:support@dropmock.com' }
]

export const socials = [
  { name: 'Instagram', target: 'https://www.instagram.com/dropmock/' },
  { name: 'Facebook', target: 'https://www.facebook.com/dropmock/' },
  { name: 'Twitter', target: 'https://twitter.com/dropmock' },
  { name: 'Blog', target: 'https://aiblog.dropmock.com/' }
]

export const mostPopular = [
  {
    name: 'Fourth of July Statue of Liberty',
    target:
      'https://ai.dropmock.com/templates/preview/cljmuehtv7hfx08482apzuntn'
  },
  {
    name: 'Pizza Delivery Guy 3D 1',
    target:
      'https://ai.dropmock.com/templates/preview/cljh3ohrcqvcd0748xz4ljqyu'
  },
  {
    name: 'Frog Billboard 01',
    target:
      'https://ai.dropmock.com/templates/preview/cljgzg1iwqk4e07484fz3o2m5'
  },
  {
    name: 'Girl leaning on signage, yellow background',
    target:
      'https://ai.dropmock.com/templates/preview/clhg4m1rwq0180748nafnzelh'
  },
  {
    name: 'Dog Character Signage - Blue green gradient background 2',
    target:
      'https://ai.dropmock.com/templates/preview/clhosj5q92my20748d8lncsqy'
  },
  {
    name: '3D Character Girl - Red and Green Gradient Background',
    target:
      'https://ai.dropmock.com/templates/preview/cli4uxv5blxqg07482tzku5tw'
  },
  {
    name: 'Character Billboard Ai',
    target:
      'https://ai.dropmock.com/templates/preview/cliix879i57j207486ddtxzm6'
  },
  {
    name: 'Happy Flamingo Ai',
    target:
      'https://ai.dropmock.com/templates/preview/clismxktgcwlx0748tlhfw1tn'
  }
]
