import {
  createGenerateClassName,
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'

const theme = createTheme({
  palette: {
    text: {
      primary: '#333333'
    },
    primary: {
      light: '#f5fbff',
      main: '#2a76e2'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ff1a6e'
    },
    success: {
      main: '#32cbd5',
      contrastText: '#fff'
    }
  },
  shape: {
    // borderRadius: 20,
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        backgroundColor: blueGrey[700],
        '&:hover': {
          backgroundColor: blueGrey[600],
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: blueGrey[700]
          }
        }
      }
    }
  },
  typography: {
    htmlFontSize: 18,
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 700,
      fontSize: '5rem'
    },
    h2: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 700,
      fontSize: '2.625rem'
    },
    h3: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '1.75rem'
    },
    h4: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 700
    },
    h5: {
      fontFamily: '"Poppins", sans-serif'
    },
    h6: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 300
    }
  }
})

export const generateClassName = createGenerateClassName({
  seed: 'dm',
  productionPrefix: 'drmk'
})

export default responsiveFontSizes(theme)
