export default [
  {
    path: '/',
    url: '/',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/',
    url: '/dashboard',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/home',
    url: '/dashboard',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/login',
    url: '/login',
    settings: {
      auth: false,
      publicOnly: true,
      admin: false
    }
  },
  {
    path: '/logout',
    url: '/logout',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/signup',
    url: '/signup',
    settings: {
      auth: false,
      publicOnly: true,
      admin: false
    }
  },
  {
    path: '/forgotPassword',
    url: '/forgotPassword',
    settings: {
      auth: false,
      publicOnly: true,
      admin: false
    }
  },
  {
    path: '/dashboard',
    url: '/dashboard',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/dashboard/:section',
    url: '/dashboard/[section]',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/editor/create/:id',
    url: '/editor/create/[id]',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/editor/user/:type/:id',
    url: '/editor/user/[type]/[id]',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/admin',
    url: '/admin',
    settings: {
      auth: true,
      publicOnly: false,
      admin: true
    }
  },
  {
    path: '/pricing',
    url: '/pricing',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates',
    url: '/templates/recommended',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates/recommended',
    url: '/templates/recommended',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates/explore',
    url: '/templates/explore',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates/preview/:template',
    url: '/templates/preview/[id]',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates/watch/:id',
    url: '/templates/watch/[id]',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/templates/sales/:id',
    url: '/templates/sales/[id]',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/verify/:token',
    url: '/verify/[token]',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/reset/:token',
    url: '/reset/[token]',
    settings: {
      auth: false,
      publicOnly: true,
      admin: false
    }
  },
  {
    path: '/library',
    url: '/library',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/deleteAccount',
    url: '/deleteAccount',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/account',
    url: '/account',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/addons',
    url: '/addons',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false,
      view: 'renders'
    }
  },
  {
    path: '/webinars',
    url: '/webinars',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/local-partners-training',
    url: '/local-partners-training',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/blogs',
    url: '/blogs',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/newsletters',
    url: '/newsletters',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/terms',
    url: '/terms',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/policy',
    url: '/policy',
    settings: {
      auth: false,
      publicOnly: false,
      admin: false
    }
  },
  {
    path: '/prompts',
    url: '/prompts',
    settings: {
      auth: true,
      publicOnly: false,
      admin: false
    }
  }
]
